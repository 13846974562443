<bit-dialog dialogSize="large" [title]="'bulkConfirmStatus' | i18n">
  <ng-container bitDialogContent>
    <div class="tw-text-center" *ngIf="loading">
      <i class="bwi bwi-spinner bwi-spin" title="{{ 'loading' | i18n }}" aria-hidden="true"></i>
      {{ "loading" | i18n }}
    </div>
    <bit-table *ngIf="!loading">
      <ng-container header>
        <tr>
          <th colspan="2" bitCell>{{ "user" | i18n }}</th>
          <th bitCell>{{ "status" | i18n }}</th>
        </tr>
      </ng-container>
      <ng-template body>
        <tr bitRow *ngFor="let item of users">
          <td width="30" bitCell>
            <bit-avatar [text]="item.user | userName" [id]="item.user.id" size="small"></bit-avatar>
          </td>
          <td bitCell>
            {{ item.user.email }}
            <small class="tw-text-muted tw-block" *ngIf="item.user.name">{{
              item.user.name
            }}</small>
          </td>
          <td class="tw-text-danger" *ngIf="item.error" bitCell>
            {{ item.message }}
          </td>
          <td *ngIf="!item.error" bitCell>
            {{ item.message }}
          </td>
        </tr>
      </ng-template>
    </bit-table>
  </ng-container>
  <ng-container bitDialogFooter>
    <button bitButton type="button" buttonType="secondary" bitDialogClose>
      {{ "close" | i18n }}
    </button>
  </ng-container>
</bit-dialog>
